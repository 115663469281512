import React, { FC } from 'react';

import './App.css';
import { Box } from '@mui/material';
import { Home } from './Pages/Home';
import { ApiProvider, AuthProvider, DaoProvider, Layout, LoginCallback, Logout, NoMatch, useAuth } from '@overremorto/ocu-client-core';
import { Routes, Route, Link } from 'react-router-dom';
import { PartnerFinderProvider } from './Providers/context.provider';
import axios from 'axios';
import { UwuableTypography } from './components/UwuableTypography';

export type AppProps = {

}


const AuthenticatedHome: React.VFC = () => {
  return <Home />
}

export const HomeSwitch = () => {
  const auth = useAuth();
  if (auth.user) {
    return <AuthenticatedHome />
  }
  else {
    return <UnauthenticatedHome />
  }
}

export const UnauthenticatedHome = () => {
  const returnUrl = window.location.protocol + '//' + window.location.host;
  return <Box sx={{ marginTop: 5 }}>

    <Box>
      <UwuableTypography sx={{ display: 'inline' }}>Login to Steam</UwuableTypography> <Link to={process.env.REACT_APP_API_URL + `/Login?returnurl=${returnUrl}/logincallback`}>
        <img src="https://community.cloudflare.steamstatic.com/public/images/signinthroughsteam/sits_01.png" alt="Sign in through Steam" />
      </Link><UwuableTypography sx={{ display: 'inline' }}>to start cheating on your current lane partner</UwuableTypography>
    </Box>
  </Box>
}


export const App: FC<AppProps> = () => {
  return (
    <div className="App">
      <ApiProvider axios={axios as any}>
        <AuthProvider>
          <DaoProvider>
            <PartnerFinderProvider>
              <Routes>
                <Route path="/" element={<Layout />}>
                  <Route index element={<HomeSwitch />} />
                  <Route path="player/:accountId" element={<HomeSwitch />} />
                  <Route path="logincallback" element={<LoginCallback />} />
                  <Route path="logout" element={<Logout />} />
                  <Route path="*" element={<NoMatch />} />
                </Route>
              </Routes>
            </PartnerFinderProvider>
          </DaoProvider>
        </AuthProvider>
      </ApiProvider>
    </div>
  );
}

export default App;
