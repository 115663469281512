import { Box, CircularProgress, Typography } from "@mui/material";
import { useDao } from "@overremorto/ocu-client-core";
import { FC, useEffect, useMemo, useState } from "react";
import { ILanePartnerFilters, LanePartnerSuggestionHero, LanePartnerSuggestionPlayer, PartnerFinderDAO, SteamProfile } from "../DAOs/PartnerFinder.dao";
import { LanePartnerList } from "../components/LanePartnerList";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { Filter } from "../components/Filter";

export type HomeProps = {

}

const Table = styled.table`
    & {
        border-collapse: collapse;
    }
    tr > td:nth-of-type(3), tr > th:nth-of-type(3) {
        background-color: #8ecaff;
    }
    tr > td:nth-of-type(4), tr > th:nth-of-type(4) {
        background-color: #2ED12E;
    }
    tr > td:nth-of-type(5), tr > th:nth-of-type(5) {
        background-color: #80FF80;
    }
    tr > td:nth-of-type(6), tr > th:nth-of-type(6) {
        background-color: #A4FFA4;
    }
    tr > td:nth-of-type(7), tr > th:nth-of-type(7) {
        background-color: #FFFB80;
    }
    tr > td:nth-of-type(8), tr > th:nth-of-type(8) {
        background-color: #FF4949;
    }
    tr > td:nth-of-type(9), tr > th:nth-of-type(9) {
        background-color: #FF6D6D;
    }
`

export const Home: FC<HomeProps> = () => {
    const { accountId } = useParams();
    const dao = useDao().daoFactory.getDao(PartnerFinderDAO) as PartnerFinderDAO;
    const [byPlayerData, setByPlayerData] = useState<LanePartnerSuggestionPlayer[][]>([]);
    const [byHeroData, setByHeroData] = useState<LanePartnerSuggestionHero[][]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [profile, setProfile] = useState<SteamProfile | undefined>(undefined);
    const [partnerType, setPartnerType] = useState<'byPlayer' | 'byHero'>('byPlayer');
    const [filters, setFilters] = useState<ILanePartnerFilters>({ startTime: new Date(2023, 0, 1) });

    let accountIdNumber: number | undefined = useMemo(() => {
        try {
            if (accountId) {
                return parseInt(accountId, 10);
            }
        }
        catch {
            //do nothing
        }
        return undefined;
    }, [accountId]);


    useEffect(() => {
        setByPlayerData([]);
        setByHeroData([]);
        (async () => {
            const p = (await dao.getSteamProfiles(accountIdNumber ? [accountIdNumber] : []))?.[0]
            setProfile(p);
        })();
    }, [dao, accountIdNumber])

    useEffect(() => {
        setByPlayerData([]);
        setByHeroData([]);

    }, [filters]);

    useEffect(() => {
        (async () => {
            if (partnerType === 'byPlayer' && byPlayerData.length === 0) {
                setIsLoading(true);
                const group = await dao.getLanePartnerByPlayer(accountIdNumber, filters);
                setByPlayerData(group);
                setIsLoading(false);
            }
            else if (partnerType === 'byHero' && byHeroData.length === 0) {
                setIsLoading(true);
                const group = await dao.getLanePartnerByHero(accountIdNumber, filters);
                setByHeroData(group);
                setIsLoading(false);
            }
        })();
    }, [dao, partnerType, accountIdNumber, byPlayerData, byHeroData, filters]);

    return (<Box sx={{ mt: '24px', '& th,& td': { px: '8px' } }}>
        {profile ? (<>
            <img src={profile.avatarmedium} alt={profile.personaname} />
            <Typography>{profile.personaname}</Typography>
        </>) : undefined}
        <Box>
            <label><input checked={partnerType === 'byPlayer'} type="radio" onChange={() => setPartnerType('byPlayer')} />Show Partner By Player</label>
            <label><input checked={partnerType === 'byHero'} type="radio" onChange={() => setPartnerType('byHero')} />Show Partner By Hero</label>
        </Box>
        <Box>
            <Filter onChange={(filters) => {
                console.log(filters);
                setFilters(filters)
            }} initialFilters={filters} />
        </Box>
        <Table>
            <thead>
                <tr>
                    <th>{partnerType === 'byPlayer' ? 'Player' : 'Hero'}</th>
                    <th>Win Rate</th>
                    <th>Lane Win +
                        <br />Draw Rate</th>
                    <th>Lane Win
                        <br />Rate</th>
                    <th>Lane Major
                        <br />Win Rate</th>
                    <th>Lane Minor
                        <br />Win Rate</th>
                    <th>Lane Draw
                        <br />Rate</th>
                    <th>Lane Major
                        <br />Loss Rate</th>
                    <th>Lane Minor
                        <br />Loss Rate</th>
                    <th>Number of <br />Games</th>
                </tr>
            </thead>
            <tbody>
                {isLoading ? (<tr><td colSpan={10}><CircularProgress /></td></tr>) : (
                    partnerType === 'byPlayer' ?
                        byPlayerData.map((g, idx) => g.length > 0 && <LanePartnerList key={idx + 1} type="byPerson" list={g} position={idx + 1} />) :
                        byHeroData.map((g, idx) => g.length > 0 && <LanePartnerList key={idx + 1} type="byHero" list={g} position={idx + 1} />)
                )}
            </tbody>
        </Table>
    </Box>)
}