import React, { FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import Uwuifier from "uwuifier";

export interface IPartnerFinderContext {
    uwuMode: boolean;
    uwuifier: Uwuifier;
}

export const PartnerFinderContext = React.createContext<IPartnerFinderContext>({
    uwuMode: false,
    uwuifier: new Uwuifier()
});


export const usePartnerFinderContext = () => useContext(PartnerFinderContext);
export const PartnerFinderProvider: FC<PropsWithChildren> = ({ children }) => {

    const [uwuMode] = useState<boolean>(false);
    const [uwuifier] = useState<Uwuifier>(new Uwuifier());


    useEffect(() => {
        document.title = uwuMode ? uwuifier.uwuifySentence('Partner Finder') : 'Partner Finder';
    }, [uwuMode, uwuifier]);

    const values = useMemo(() => ({
        uwuMode, uwuifier
    }), [uwuMode, uwuifier])

    return <PartnerFinderContext.Provider value={values}>{children}</PartnerFinderContext.Provider >
}