import { Box, TextField } from "@mui/material";
import { FC, useEffect, useState } from "react"
import { parse, isValid, format } from 'date-fns';
import { ILanePartnerFilters } from "../DAOs/PartnerFinder.dao";



export type FilterProps = {
    initialFilters?: ILanePartnerFilters;
    onChange: (filters: ILanePartnerFilters) => void;
}

export const parseDate = (str: string): Date | undefined => {
    if (!str) {
        return undefined;
    }
    const parsed = parse(str, 'MM/dd/yyyy', new Date());
    return isValid(parsed) && parsed.getFullYear() >= 1000 ? parsed : undefined;
}

export const DateField: FC<{ label: string; initialValue?: Date; onChange: (date: Date | undefined) => void }> = ({ label, initialValue, onChange }) => {
    const [dateStr, setDateStr] = useState<string>(initialValue ? format(initialValue, 'yyyy-MM-dd') : '');
    const [lastChangeDate, setLastChangeDate] = useState<Date | undefined>(initialValue);
    return (<TextField type='date'
        InputLabelProps={{ shrink: true }}
        label={label}
        onChange={(e) => {
            const text = e.target.value
            console.log(text);
            const split = text.split('-');
            split[0] = split[0].substring(0, 4)
            const newText = split.join('-');
            setDateStr(newText);
        }} value={dateStr}

        onKeyUp={(e) => {
            if (e.key === 'Enter') {
                const date = parse((e.target as HTMLInputElement).value, 'yyyy-MM-dd', new Date());
                if (date?.getTime() !== lastChangeDate?.getTime()) {
                    onChange(date);
                    setLastChangeDate(date);
                }
            }
        }}
        onBlur={(e) => {

            const date = parse(e.target.value, 'yyyy-MM-dd', new Date());
            if (date?.getTime() !== lastChangeDate?.getTime()) {
                onChange(date);
                setLastChangeDate(date);
            }

        }} />)
}

export const Filter: FC<FilterProps> = ({ initialFilters, onChange }) => {
    const [filters, setFilters] = useState<ILanePartnerFilters>(initialFilters ?? {})
    useEffect(() => {
        onChange(filters);
    }, [filters, onChange]);
    return (<Box sx={{ py: '16px' }}>
        <DateField label='Start Date' initialValue={initialFilters?.startTime} onChange={(date) => {
            setFilters({ ...filters, startTime: date });
        }} />
        <DateField label='End Date' initialValue={initialFilters?.endTime} onChange={(date) => {
            setFilters({ ...filters, endTime: date });
        }} />

    </Box>)
}