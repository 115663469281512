import { FC } from "react";
import { LanePartnerSuggestionHero, LanePartnerSuggestionPlayer } from "../DAOs/PartnerFinder.dao";
import { Link } from "react-router-dom";

export type LanePartnerProps = {
    type: 'byHero' | 'byPerson';
    lanePartner: LanePartnerSuggestionPlayer | LanePartnerSuggestionHero;
}
export const LanePartner: FC<LanePartnerProps> = ({ lanePartner, type }) => {
    return (<tr>
        {/* <td>{lanePartner.hero_name}</td> */}
        {type === 'byPerson' ? (<td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}>{(lanePartner as LanePartnerSuggestionPlayer).ally_account_id ?
            (<Link to={`/player/${(lanePartner as LanePartnerSuggestionPlayer).ally_account_id}`}>
                {<img src={(lanePartner as LanePartnerSuggestionPlayer).ally_img} alt="" width={32} height={32} style={{ display: 'inline-block', verticalAlign: 'middle' }} />}
                <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>{(lanePartner as LanePartnerSuggestionPlayer).ally_player_name_real}</span>
            </Link>) : (<>
                <img src={(lanePartner as LanePartnerSuggestionPlayer).ally_img} alt="" width={32} height={32} style={{ display: 'inline-block', verticalAlign: 'middle' }} />
                <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>RANDO</span></>)
        }
        </td>) :
            (<td style={{ textAlign: 'left', whiteSpace: 'nowrap' }}><>
                <img style={{
                    display: 'inline-block', verticalAlign: 'middle'
                }}
                    src={(lanePartner as LanePartnerSuggestionHero).ally_hero_img} alt="" height={32} />
                <span style={{ display: 'inline-block', verticalAlign: 'middle' }}>{(lanePartner as LanePartnerSuggestionHero).ally_hero_name ?? 'RANDO'}</span></>
            </td>)
        }
        <td>{lanePartner.win_rate?.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}</td>
        <td>{lanePartner.lane_win_draw_rate?.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}</td>
        <td>{lanePartner.lane_win_rate?.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}</td>
        <td>{lanePartner.lane_major_win_rate?.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}</td>
        <td>{lanePartner.lane_minor_win_rate?.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}</td>
        <td>{lanePartner.lane_draw_rate?.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}</td>
        <td>{lanePartner.lane_major_loss_rate?.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}</td>
        <td>{lanePartner.lane_minor_loss_rate?.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: 2 })}</td>
        <td>{lanePartner.total_matches}</td>
    </tr >);
}