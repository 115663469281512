import { TypographyProps, Typography } from "@mui/material";
import { useCallback } from "react";
import { usePartnerFinderContext } from "../Providers/context.provider";

export const UwuableTypography = (props: TypographyProps) => {
    const { uwuMode, uwuifier } = usePartnerFinderContext();

    const uwu = useCallback((children: any | any[]) => {
        if (!children) {
            return children;
        }
        if (Array.isArray(children)) {
            return children.map(c => typeof c === 'string' ? uwuifier.uwuifySentence(c) : c);
        }
        else {
            return typeof children === 'string' ? uwuifier.uwuifySentence(children) : children;
        }
    }, [uwuifier])
    const children = uwuMode && props.children ? uwu(props.children) : props.children;
    return <Typography {...props}>
        {children}
    </Typography>
}