import { FC } from "react";
import { LanePartnerSuggestionHero, LanePartnerSuggestionPlayer } from "../DAOs/PartnerFinder.dao";
import { LanePartner } from "./LanePartner";
import { Typography } from "@mui/material";

export type LanePartnerListProps = {
    type: 'byHero' | 'byPerson';
    list: LanePartnerSuggestionPlayer[] | LanePartnerSuggestionHero[];
    position: number;
}
export const LanePartnerList: FC<LanePartnerListProps> = ({ list, type, position }) => {
    return (<>
        <tr ><td colSpan={10}><Typography sx={{ fontSize: '24px', fontWeight: 'bold' }}>{position}</Typography></td></tr>

        {list.map((lp) => <LanePartner key={`${position}_${(lp as LanePartnerSuggestionPlayer).ally_account_id ?? (lp as LanePartnerSuggestionHero).ally_hero_id}`} type={type} lanePartner={lp} />)}

    </>);
}