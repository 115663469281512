import { BaseDAO } from "@overremorto/ocu-client-core";
import _, { isDate, isNumber } from "lodash";

export interface LanePartnerSuggestionBase {
    account_id: number;
    position: number;

    win_rate: number;
    wins: number;
    lane_win_draw_rate: number;
    lane_win_rate: number;
    lane_major_win_rate: number;
    lane_minor_win_rate: number;
    lane_draw_rate: number;
    lane_loss_rate: number;
    lane_major_loss_rate: number;
    lane_minor_loss_rate: number;

    total_matches: number;
}

export interface LanePartnerSuggestionPlayer extends LanePartnerSuggestionBase {
    ally_account_id?: number;
    ally_player_name_real?: string;
    ally_img?: string;
}


export interface LanePartnerSuggestionHero extends LanePartnerSuggestionBase {
    ally_hero_id?: string;
    ally_hero_name?: string;
    ally_hero_img?: string;
}

export type SteamProfile = {
    steamid: string;
    communityvisibilitystate: number;
    profilestate: number;
    personaname: string;
    profileurl: string;
    avatar: string;
    avatarmedium: string;
    avatarfull: string;
    avatarhash: string;
    lastlogoff: number;
    personastate: number;
    primaryclanid: string;
    timecreated: number;
    personastateflags: number;
    loccountrycode: string;
    locstatecode: string;
    account_id: number;
}

export interface ILanePartnerFilters {

    startTime?: Date | undefined;
    endTime?: Date | undefined;
    allyAccountId?: number | undefined;
    heroId?: number | undefined;
    allyHeroId?: number | undefined;
    minMatchCount?: number | undefined;
    position?: number | undefined;
}

const filtersToUrlParams = (filters: ILanePartnerFilters | undefined) => {
    if (!filters) {
        return undefined;
    }

    let params = '';
    for (const k of Object.keys(filters)) {
        const key = k as keyof ILanePartnerFilters;
        if (filters[key] === undefined || filters[key] === null) {
            continue
        }

        params += `&${key}=`;
        let value: string | Date | number | boolean = filters[key]!;
        if (isDate(value)) {
            value = value.toISOString();
        }
        if (isNumber(value)) {
            value = value.toString();
        }
        params += value;
    }

    return params;
}

export class PartnerFinderDAO extends BaseDAO {
    async getLanePartnerByPlayer(accountId?: number, filters?: ILanePartnerFilters): Promise<LanePartnerSuggestionPlayer[][]> {
        const data = await this.api.get<LanePartnerSuggestionPlayer[]>(`GetLanePartner?accountId=${accountId ?? ''}&type=byPerson&minMatchCount=3${filtersToUrlParams(filters)}`);

        const group: LanePartnerSuggestionPlayer[][] = [[], [], [], [], []];
        const avatars = await this.getSteamProfiles(_.uniq(data.map(d => d.ally_account_id!).filter(d => !!d)));
        for (const row of data) {
            if (!row.ally_account_id) {
                row.ally_img = '/rando_square.png';
            }
            else {
                row.ally_img = avatars.find((a) => a.account_id === row.ally_account_id)?.avatar;
            }

            if (!group[row.position - 1]) {
                group[row.position - 1] = [];
            }
            group[row.position - 1].push(row);
        }

        return group;
    }
    async getLanePartnerByHero(accountId?: number, filters?: ILanePartnerFilters): Promise<LanePartnerSuggestionHero[][]> {
        const data = await this.api.get<LanePartnerSuggestionHero[]>(`GetLanePartner?accountId=${accountId ?? ''}&type=byHero&minMatchCount=3${filtersToUrlParams(filters)}`);

        const group: LanePartnerSuggestionHero[][] = [[], [], [], [], []];

        for (const row of data) {
            if (!row.ally_hero_id) {
                row.ally_hero_img = '/rando_wide.png';
            }

            if (!group[row.position - 1]) {
                group[row.position - 1] = [];
            }
            group[row.position - 1].push(row);
        }


        return group;
    }
    async getLanePartnerRaw(): Promise<LanePartnerSuggestionBase[]> {
        const data = await this.api.get<LanePartnerSuggestionBase[]>('GetLanePartner');
        return data;
    }
    async getSteamProfiles(accountIds: number[]): Promise<SteamProfile[]> {
        const data = await this.api.get<SteamProfile[]>(`GetSteamProfile?ids=${accountIds.join(',')}`);
        return data;
    }
}